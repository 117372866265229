<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
          <!-- HTML/CSS Edit Modal -->
       <b-modal id="html-modal" title="Insert HTML">
      <b-form-textarea v-model="htmlContent" rows="6" placeholder="Enter HTML code"></b-form-textarea>
      <b-button variant="primary" @click="applyHtmlContent">Save</b-button>
      <b-button variant="outline-secondary" @click="closeHtmlModal">Cancel</b-button>
    </b-modal>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="London Contact Information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
              >
                <quill-editor
                  ref="footer_contact1Editor"
                  v-model="addCourseForm.footer_contact1"
                  :options="getEditorOptions('footer_contact1Editor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Dubai Contact Information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Dubai"
              >
                <quill-editor
                  ref="footer_contact2Editor"
                  v-model="addCourseForm.footer_contact2"
                  :options="getEditorOptions('footer_contact2Editor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Kuala Lumpur Contact Information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Kuala"
              >
                <quill-editor
                  ref="footer_contact3Editor"
                  v-model="addCourseForm.footer_contact3"
                  :options="getEditorOptions('footer_contact3Editor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Barcelona contact information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Barcelona"
              >
                <quill-editor
                  ref="footer_contact4Editor"
                  v-model="addCourseForm.footer_contact4"
                  :options="getEditorOptions('footer_contact4Editor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Select City"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                rules=""
              >
                <v-select
                data-testid="footer-city"
                  v-model="addCourseForm.footer_cities"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                rules=""
              >
                <v-select
                data-testid="footer-category"
                  v-model="addCourseForm.footer_categories"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  multiple
                  :options="coursesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Whatsapp link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input 
                data-testid="footer-whatsapp-link"
                v-model="addCourseForm.footer_whatsapp" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Facebook link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input 
                data-testid="footer-facebook-link"
                v-model="addCourseForm.footer_facebook" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Twitter link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input 
                data-testid="footer-twitter-link"
                v-model="addCourseForm.footer_twitter" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Linked-in link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input 
                data-testid="footer-linkedin-link"
                v-model="addCourseForm.footer_linkedin" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="footer-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
      <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <!--/ form -->
  </b-card>
</template>
<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import store from '@/store'

// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)
const Parchment = Quill.import('parchment')
const ClassAttributor = new Parchment.Attributor.Class('class', 'class', {
  scope: Parchment.Scope.BLOCK + Parchment.Scope.INLINE,
});

Quill.register(ClassAttributor, true);
// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    const { proxy } = getCurrentInstance()
    const citiesList = ref([])

    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })

    const form = ref({
      footer_contact1: '',
      footer_contact2: '',
      footer_contact3: '',
      footer_contact4: '',
      footer_cities: '',
      footer_categories: '',
      footer_whatsapp: '',
      footer_facebook: '',
      footer_linkedin: '',
      footer_twitter: '',
    })
    const addCourseForm = reactive(form)

    const data = [
      'footer_contact1',
      'footer_contact4',
      'footer_contact2',
      'footer_contact3',
      'footer_cities',
      'footer_categories',
      'footer_whatsapp',
      'footer_facebook',
      'footer_linkedin',
      'footer_twitter',
    ]
    const formData = new FormData()
    formData.append('data', JSON.stringify(data))
    store.dispatch('setting/GetSettingArray', formData).then(response => {
      response.data.forEach(el => {
        if (el.name === 'footer_categories') {
          form.value.footer_categories = el.value
        }
        if (el.name === 'footer_twitter') {
          form.value.footer_twitter = el.value
        }
        if (el.name === 'footer_facebook') {
          form.value.footer_facebook = el.value
        }
        if (el.name === 'footer_whatsapp') {
          form.value.footer_whatsapp = el.value
        }
        if (el.name === 'footer_linkedin') {
          form.value.footer_linkedin = el.value
        }
        if (el.name === 'footer_contact1') {
          form.value.footer_contact1 = el.value
        }
        if (el.name === 'footer_contact2') {
          form.value.footer_contact2 = el.value
        }
        if (el.name === 'footer_contact3') {
          form.value.footer_contact3 = el.value
        }
        if (el.name === 'footer_contact4') {
          form.value.footer_contact4 = el.value
        }
      })
    })

    const coursesList = ref([])

    store.dispatch('categories/AllCategory').then(response => {
      coursesList.value = response.data.data
    })
    const htmlContent = ref('');
    let currentEditorRef = null;

    const addCourseFormvalidate = ref()
  const openHtmlModal = (editorRef) => {
      currentEditorRef = editorRef;
      htmlContent.value = proxy.$refs[editorRef].quill.root.innerHTML;
      proxy.$bvModal.show('html-modal');
    };

    const insertHtml = () => {
      if (currentEditorRef) {
        proxy.$refs[currentEditorRef].quill.clipboard.dangerouslyPasteHTML(
          proxy.$refs[currentEditorRef].quill.getSelection(true).index,
          htmlContent.value
        );
        proxy.$bvModal.hide('html-modal');
      }
    };
    const save = () => {
      const formData = new FormData()

      const data = [
        { name: 'footer_contact1', value: addCourseForm.value.footer_contact1 },
        { name: 'footer_contact2', value: addCourseForm.value.footer_contact2 },
        { name: 'footer_contact3', value: addCourseForm.value.footer_contact3 },
        { name: 'footer_contact4', value: addCourseForm.value.footer_contact4 },
        { name: 'footer_cities', value: addCourseForm.value.footer_cities },
        { name: 'footer_categories', value: addCourseForm.value.footer_categories },
        { name: 'footer_whatsapp', value: addCourseForm.value.footer_whatsapp },
        { name: 'footer_facebook', value: addCourseForm.value.footer_facebook },
        { name: 'footer_linkedin', value: addCourseForm.value.footer_linkedin },
        { name: 'footer_twitter', value: addCourseForm.value.footer_twitter },
      ]

      formData.append('data', JSON.stringify(data))

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          store.dispatch('setting/updateSettingArray', formData)
            .then(() => {
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    function example_image_upload_handler(blobInfo, success, failure, progress) {
      const xhr = new XMLHttpRequest()
      const formData = new FormData()
      const token = localStorage.getItem('token')

      xhr.withCredentials = false
      xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        const json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }

    const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image','code-block'],
             [ 'html'], // Custom button
          ],
          handlers: {
            image: () => imageHandler(editorRef),
              link: () => linkHandler(editorRef),
               'html': () => openHtmlModal(editorRef),
          },
        },
      },
    })
const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

  const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }
   const applyHtmlContent = () => {
      if (currentEditorRef) {
        const quill = proxy.$refs[currentEditorRef].quill;

        // Clear the existing content in the editor
        quill.setContents([]);
console.log("eee")
        // Insert the new HTML content
       quill.clipboard.dangerouslyPasteHTML(0, htmlContent.value);

        // Hide the modal after updating
        closeHtmlModal();
      }
    };

    const closeHtmlModal = () => {
      proxy.$bvModal.hide('html-modal');
    };

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


    return {
      example_image_upload_handler,
      addCourseForm,
      form,
      addCourseFormvalidate,
      coursesList,
      citiesList,
      save,
      required,
      email,
      applyHtmlContent,
      closeHtmlModal,
           linkHandler,
              htmlContent,
      openHtmlModal,
      insertHtml,
       imageHandler,
      insertImage,
      imageProperties,
      getEditorOptions,
    }
  },

  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
  .col-12 {
    margin: 0px !important;
  }
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
